<script setup lang="ts">
const isOpen = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const coupon = ref<string>("");

const { addPromotionCode } = useCart();

const onSubmit = async (e: Event) => {
	e.preventDefault();

	if (!coupon.value) return;

	isLoading.value = true;
	await addPromotionCode(coupon.value);
	isOpen.value = false;
	isLoading.value = false;
	coupon.value = "";
};
</script>

<template>
	<div class="">
		<div class="flex justify-between">
			<span class="font-medium">Couponcode?</span>
			<button class="underline font-medium" @click="isOpen = !isOpen">
				{{ isOpen ? "Sluiten" : "Toevoegen" }}
			</button>
		</div>
		<div
			class="grid transition-[grid-template-rows] duration-500"
			:class="[isOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]']"
		>
			<form @submit="onSubmit" class="overflow-hidden">
				<div class="flex flex-col gap-2 overflow-hidden">
					<KippieInput v-model="coupon" placeholder="Couponcode" class="mt-2" />
					<KippieButton type="submit" size="small" color="yellow" :loading="isLoading">
						Couponcode toepassen
					</KippieButton>
				</div>
			</form>
		</div>
	</div>
</template>
